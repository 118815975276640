import React, {FC, useMemo, useState} from "react"
import {SourceDatabaseDtoDetail, SourceDatabaseType} from "models/SourceDatabase"
import {Button, Checkbox, Form, Input, Popconfirm, Select, Space, Table} from "antd"
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {useDispatch} from "hooks"
import {ColumnType} from "antd/es/table"

interface Props {
  sourceDatabases: SourceDatabaseDtoDetail[],
  paginationEnabled?: boolean

}

interface DataRow {
  code: string,
  type: string,
  toBackup: boolean
  key: string;
  description: string
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'db-type' | 'backup' | 'text-area';
  record: DataRow;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
                                                                              editing,
                                                                              dataIndex,
                                                                              title,
                                                                              inputType,
                                                                              record,
                                                                              index,
                                                                              children,
                                                                              ...restProps
                                                                            }) => {
  let inputNode;
  switch (inputType) {
    case 'db-type':
      inputNode = <Select showSearch
                          style={{width: 200}}
                          options={Object.values(SourceDatabaseType).map(dbType => ({
                            label: dbType,
                            value: dbType,
                          }))}/>
      break;
    case "backup":
      inputNode = <Checkbox/>
      break;
    default:
      inputNode = <Input.TextArea/>
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          valuePropName={inputType === "backup" ? "checked" : undefined}
          rules={[
            {
              required: inputType !== "text-area",
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};


const SourceDatabaseList: FC<Props> = ({
                                         sourceDatabases,
                                         paginationEnabled = true,
                                       }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch()
  const [filterByCode, setFilterByCode] = useState("")
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: DataRow) => record.key === editingKey;

  const edit = (record: Partial<DataRow> & { key: React.Key }) => {
    form.setFieldsValue({...record});
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const columns: ColumnType<DataRow>[] = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      // ...getColumnSearchProps('code'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: Object.values(SourceDatabaseType).map((type => ({
        text: type,
        value: type,
      }))),
      filterSearch: true,
      onFilter: (value, record: DataRow) => record.type === String(value),
      sorter: (a, b) => a.type.localeCompare(b.type),
      // @ts-ignore
      editable: true,
    },
    {
      title: 'Backup',
      dataIndex: 'toBackup',
      key: 'toBackup',
      filters: [true, false].map((toBackup => ({
        text: toBackup ? 'yes' : 'no',
        value: toBackup,
      }))),
      filterSearch: true,
      onFilter: (value, record: DataRow) => record.toBackup === value,
      sorter: (a, b) => Number(a.toBackup) - Number(b.toBackup),
      render: (value, record, index) => record.toBackup ? <div>yes</div> : <div>no</div>,
      // @ts-ignore
      editable: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      // @ts-ignore
      editable: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        if (editable) {
          return (
            <Space>
              <Button onClick={cancel}>Cancel</Button>
              <Button type={"primary"} onClick={() => save(record.key)}>Save</Button>
            </Space>
          )
        }
        return (
          <Space size="middle">
            <Button icon={<EditOutlined/>} onClick={() => edit(record)}>Edit</Button>
            <Popconfirm
              title="Source database deletion"
              description={`Do you really want to delete the database ${record.code} ?`}
              onConfirm={() => dispatch.definition.deleteSourceDatabase(record.code)}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined/>} danger>Delete</Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ];
  const dataSource = useMemo(() =>
    sourceDatabases
      .filter((elem) => filterByCode === "" || filterByCode === undefined || elem.code.toLowerCase().includes(filterByCode.toLowerCase()))
      .sort((a, b) => a.code.localeCompare(b.code)).map((db: SourceDatabaseDtoDetail) => ({
      code: db.code,
      type: db.type,
      toBackup: db.toBackup,
      key: db.code,
      description: db.description
    })), [filterByCode, sourceDatabases])

  const save = async (key: React.Key) => {
    const sourceDb = dataSource.find((item) => key === item.key);
    if (!sourceDb) {
      console.error("could not find sourceDb")
      return
    }
    const underscorePos = sourceDb.code.indexOf("_")
    const row = (await form.validateFields()) as DataRow;
    dispatch.definition.editSourceDatabase({
      sourceDatabaseCode: sourceDb.code,
      dto: {
        name: sourceDb.code.substring(underscorePos + 1),
        type: row.type,
        toBackup: row.toBackup,
        description: row.description,
      },
    })
    setEditingKey('');
  };


  const mergedColumns: ColumnType<DataRow>[] = columns?.map((col) => {
    // @ts-ignore
    if (!col.editable) {
      return col;
    }
    let inputType: string;
    switch (col.dataIndex) {
      case "type":
        inputType = "db-type"
        break;
      case "toBackup":
        inputType = "backup"
        break;
      case "description":
        inputType = "text-area"
        break;
      default:
        inputType = "db-type"
    }
    return {
      ...col,
      onCell: (record: DataRow) => ({
        record,
        inputType,
        dataIndex: col.dataIndex,
        title: col.title as any,
        editing: isEditing(record),
      }),
    };
  }) ?? [];

  return <div>
    <Input
      placeholder={"Search by code"}
      value={filterByCode}
      onChange={(e) => setFilterByCode(e.target.value)}
      style={{marginBottom: "4px"}}
    />
    <br/>
    <Form form={form} component={false}>
      <Table
        size={"small"}
        components={{
          body: {cell: EditableCell},
        }}
        bordered
        dataSource={dataSource}
        columns={mergedColumns as any}
        pagination={paginationEnabled ? {
          defaultPageSize: 15,
          onChange: cancel,
        } : false}
      />
    </Form>
  </div>
}

export default SourceDatabaseList