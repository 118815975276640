import React, {FC, useCallback} from "react"
import {CustomerDtoDetail} from "models/Customer"
import {useSelector} from "react-redux"
import {getRelationships, getUsersMap} from "redux/selectors/definition"
import {Button, Divider, List, notification, Typography} from "antd"
import {Link} from "react-router-dom"
import BironAnalystCreator from "components/BironAnalystCreator"
import useDispatch from "hooks/useDispatch"

interface Props {
  customer: CustomerDtoDetail,
}

const AnalystList: FC<Props> = ({customer}) => {
  const dispatch = useDispatch()
  const usersMap = useSelector(getUsersMap)
  const relationships = useSelector(getRelationships) || []
  const bironAnalystListData = relationships
    .filter((rel) => rel.relation === "biron_analyst")
    .map((elem) => ({
      title: elem.subject.displayName,
      firstName: usersMap?.get(elem.subject.id)?.firstName,
      lastName: usersMap?.get(elem.subject.id)?.lastName,
      id: elem.subject.id,
    }))

  const sysAdminAndSuperAnalystsIds = relationships
    .filter((rel) => rel.resource.type === "root" && (rel.relation === "sysadmin" || rel.relation === "superanalyst"))
    .map(elem => elem.subject.displayName ?? "")


  const onRevokeBiron = useCallback((id: string) => {
    dispatch.definition.deleteBironAnalystRelationship({
        userId: id,
        customerAbbrev: customer.abbreviation,
      })
      .then(() => {
      })
      .catch(() => {
      })
  }, [customer.abbreviation, dispatch.definition])

  const externalAnalystListData = customer.sequences.map((elem) =>
      elem.creators.map((analyst) => ({
        email: analyst.email,
        firstName: analyst.firstName,
        lastName: analyst.lastName,
        role: "Creator",
        id: analyst.id,
      })).concat(elem.explorers.map((analyst) => ({
        email: analyst.email,
        firstName: analyst.firstName,
        lastName: analyst.lastName,
        role: "Explorer",
        id: analyst.id,
      }))))
    .flat()

  return <div>
    <Typography.Title level={4}>
      Biron Analysts
    </Typography.Title>
    <BironAnalystCreator customerAbbreviation={customer.abbreviation} excludedUsers={
      bironAnalystListData
        .map(elem => elem.title ?? "")
        .concat(sysAdminAndSuperAnalystsIds)}/>
    <List
      bordered
      itemLayout="horizontal"
      dataSource={bironAnalystListData}
      style={{marginTop: "20px"}}
      renderItem={(item) => (
        <List.Item actions={
          [
            <Button type={"primary"} danger key={2} onClick={() => onRevokeBiron(item.id)}>Revoke</Button>,
          ]
        }>
          <List.Item.Meta
            title={
              <Link to={`/app/full-admin/user?id=${item.id}`}>{item.title}</Link>}
            description={`${item.firstName} ${item.lastName}`}
          />
        </List.Item>
      )}
    />
    <Divider/>
    <Typography.Title level={4}>
      Customer Analysts
    </Typography.Title>
    <List
      bordered
      itemLayout="horizontal"
      dataSource={externalAnalystListData}
      renderItem={(item) => (
        <List.Item actions={
          []
        }>
          <List.Item.Meta
            title={<Link to={`/app/full-admin/user?id=${item.id}`}>{item.email}</Link>}
            description={`${item.role} - ${item.firstName} ${item.lastName}`}
          />
        </List.Item>
      )}
    />
  </div>
}


export default AnalystList