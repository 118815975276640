import Api from "services/api"
import {SourceDatabaseDtoCreateOrUpdate, SourceDatabaseDtoDetailWithParents} from "models/SourceDatabase"
import {VersionDtoDetailWithParents} from "models/Version"

export const createDatabase = async (versionCode: string, db: SourceDatabaseDtoCreateOrUpdate): Promise<SourceDatabaseDtoDetailWithParents> =>
  Api.post<void, SourceDatabaseDtoDetailWithParents>(`/sourceDatabase?versionCode=${versionCode}`, db)

export const updateDatabase = async (sourceDatabaseCode: string, db: SourceDatabaseDtoCreateOrUpdate): Promise<SourceDatabaseDtoDetailWithParents> =>
  Api.put<void, SourceDatabaseDtoDetailWithParents>(`/sourceDatabase/byCode/${sourceDatabaseCode}`, db)

export const deleteDatabase = async (sourceDatabaseCode: string): Promise<VersionDtoDetailWithParents> =>
  Api.delete<void, VersionDtoDetailWithParents>(`/sourceDatabase/byCode/${sourceDatabaseCode}`)
