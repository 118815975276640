import React, {FC, useCallback, useEffect} from "react"
import {Button, Form, Input, InputNumber, Select} from "antd"
import {CustomerDtoDetail} from "models/Customer"
import {useDispatch} from "hooks"

interface Props {
  customer: CustomerDtoDetail,
}

interface FormValues {
  name: string,
  abbreviation: string,
  zendeskId?: number,
  datalakeNode: number,
  datalakeMaxMemoryUsage: number
  licenseCreatorMaxSeats: number;
  licenseExplorerMaxSeats: number;
  licenseAllowedEmailFqdns: Set<string>;
  alternateZendeskIds: Set<string>;
}

const CustomerEdition: FC<Props> = ({customer}) => {
  const [form] = Form.useForm<FormValues>();
  const dispatch = useDispatch()

  useEffect(() => {
    form.resetFields()
  }, [customer, form]);

  const onFinish = useCallback((values: FormValues) => {
    dispatch.definition.updateCustomer({
      ...customer,
      zendeskId: values.zendeskId,
      alternateZendeskIds: [...values.alternateZendeskIds],
      datalake: {
        ...customer.datalake,
        node: values.datalakeNode,
        maxMemoryUsage: values.datalakeMaxMemoryUsage,
      },
      license: {
        ...customer.license,
        creatorMaxSeats: values.licenseCreatorMaxSeats,
        explorerMaxSeats: values.licenseExplorerMaxSeats,
        allowedEmailFqdns: values.licenseAllowedEmailFqdns,
      },
    })
  }, [customer, dispatch.definition])

  return <Form
    form={form}
    initialValues={{
      name: customer.name,
      abbreviation: customer.abbreviation,
      zendeskId: customer.zendeskId,
      datalakeNode: customer.datalake.node,
      datalakeMaxMemoryUsage: customer.datalake.maxMemoryUsage,
      licenseCreatorMaxSeats: customer.license.creatorMaxSeats,
      licenseExplorerMaxSeats: customer.license.explorerMaxSeats,
      licenseAllowedEmailFqdns: customer.license.allowedEmailFqdns,
      alternateZendeskIds: customer.alternateZendeskIds,
    }}
    onFinish={onFinish}
  >
    <Form.Item label="Name" name={"name"}>
      <Input disabled/>
    </Form.Item>
    <Form.Item label="Abbreviation" name={"abbreviation"}>
      <Input disabled/>
    </Form.Item>
    <Form.Item label="Allowed domain names" name={"licenseAllowedEmailFqdns"}>
      <Select mode={"tags"} allowClear={true} data-lpignore="true"/>
    </Form.Item>
    <Form.Item label="Zendesk id" name={"zendeskId"}>
      <Input/>
    </Form.Item>
    <Form.Item label="Zendesk Id alternatifs" name={"alternateZendeskIds"}>
      <Select mode={"tags"} allowClear={true} data-lpignore="true"/>
    </Form.Item>
    <Form.Item label="Kirbytes node" name={"datalakeNode"} rules={[{required: true}]}>
      <InputNumber/>
    </Form.Item>
    <Form.Item label="Kirbytes max memory usage" name={"datalakeMaxMemoryUsage"}>
      <InputNumber/>
    </Form.Item>
    <Form.Item label="Max number of allowed Creators" name={"licenseCreatorMaxSeats"} rules={[{required: true}]}>
      <InputNumber/>
    </Form.Item>
    <Form.Item label="Max number of allowed Explorers" name={"licenseExplorerMaxSeats"} rules={[{required: true}]}>
      <InputNumber/>
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType={"submit"}>Update settings</Button>
    </Form.Item>
  </Form>
}


export default CustomerEdition

