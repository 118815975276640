import {createModel} from "@rematch/core"
import RootModel from "redux/models"
import {CustomerSerivce, DefinitionService, ServiceAccountService, SourceDatabaseService, UserService} from "services"
import {AppliedPermissionDto, Definitions, ObjectReferenceDto, RelationshipDto, ResourceToDefinition} from "models/Definition"
import isEqual from "lodash.isequal"
import {IamUserDtoSummary, ServiceAccountDtoCreateOrUpdate, ServiceAccountDtoDetail} from "models/User"
import {CustomerDtoDetail} from "models/Customer"
import {SourceDatabaseDtoCreateOrUpdate} from "models/SourceDatabase"
import {notification} from "antd"
import {SubjectType} from "services/DefinitionService"

interface DefinitionState {
  types: ResourceToDefinition
  resourceIds: string[]
  definition: Definitions,
  users: IamUserDtoSummary[],
  usersMap: Map<string, IamUserDtoSummary>,
  serviceAccounts: ServiceAccountDtoDetail[],
  customers: CustomerDtoDetail[]
  userPermissions: AppliedPermissionDto[],
  loading: boolean
}

const initialState: DefinitionState = {
  types: {},
  resourceIds: [],
  definition: {
    relationships: [],
    permissions: {},
  },
  users: [],
  serviceAccounts: [],
  customers: [],
  userPermissions: [],
  loading: false,
  usersMap: new Map(),
}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    setLoading(state, loading: boolean) {
      return {
        ...state,
        loading,
      }
    },
    setUsers(state, users: IamUserDtoSummary[]) {
      return {
        ...state,
        users,
        usersMap: new Map(users.map(user => [user.id, user])),
      }
    },
    setServiceAccounts(state, serviceAccounts: ServiceAccountDtoDetail[]) {
      return {
        ...state,
        serviceAccounts,
      }
    },
    setCustomers(state, customers: CustomerDtoDetail[]) {
      return {
        ...state,
        customers,
      }
    },
    setTypes(state, types: ResourceToDefinition) {
      return {
        ...state,
        types,
      }
    },
    setResourceIds(state, ids: string[]) {
      return {
        ...state,
        resourceIds: ids,
      }
    },
    setUserPermissions(state, permissions: AppliedPermissionDto[]) {
      return {
        ...state,
        userPermissions: permissions,
      }
    },
    setDefinitions(state, def: Definitions) {
      return {
        ...state,
        definition: def,
      }
    },
    addLocalRelationship(state, relationship: RelationshipDto) {
      return {
        ...state,
        definition: {
          ...state.definition,
          relationships: state.definition.relationships.concat([relationship]),
        },
      }
    },
    removeLocalRelationship(state, relationship: RelationshipDto) {
      const idxToRemove = state.definition.relationships.findIndex((elem) => isEqual(elem, relationship))
      const updated = idxToRemove > -1 ?
        state.definition.relationships.slice(0, idxToRemove).concat(state.definition.relationships.slice(idxToRemove + 1))
        : state.definition.relationships

      return {
        ...state,
        definition: {
          ...state.definition,
          relationships: updated,
        },
      }
    },
  },
  effects: (dispatch) => ({
    async loadTypes() {
      let res: ResourceToDefinition = {}
      try {
        res = await DefinitionService.getTypes()
      } catch (e) {
        res = {}

      }
      dispatch.definition.setTypes(res)
    },

    async loadUsers() {
      let res: IamUserDtoSummary[] = []
      try {
        res = await UserService.listUsers()
      } catch (e) {
        res = []
      }
      dispatch.definition.setUsers(res)
    },

    async loadServiceAccounts() {
      let res: ServiceAccountDtoDetail[] = []
      try {
        res = await UserService.listServiceAccounts()
      } catch (e) {
        res = []
      }
      dispatch.definition.setServiceAccounts(res)
    },

    async createServiceAccount(payload: {
      customerAbbrev: string,
      dto: ServiceAccountDtoCreateOrUpdate
    }, state) {
      const res = await ServiceAccountService.createServiceAccount(payload.customerAbbrev, payload.dto)
      alert(res.password)
      dispatch.definition.setServiceAccounts([...state.definition.serviceAccounts, res.serviceAccount])
    },

    async resetServiceAccountPassword(payload: {
      id: number,
    }) {
      const res = await ServiceAccountService.resetServiceAccountPassword(payload.id)
      alert(res.password)
    },

    async deleteServiceAccount(payload: {
      id: number,
    }) {
      await ServiceAccountService.deleteServiceAccount(payload.id)
      dispatch.definition.loadServiceAccounts()
    },

    async loadCustomers() {
      dispatch.definition.setCustomers(await CustomerSerivce.listCustomers())
    },

    async loadResourceIds(resource: string) {
      dispatch.definition.setResourceIds((await DefinitionService.listResourceIds(resource)) ?? [])
    },

    async loadObjectReferenceDefinition(payload: ObjectReferenceDto) {
      dispatch.definition.setDefinitions(await DefinitionService.listResourcesAndPermissions(payload.type, payload.id))
    },

    async loadObjectReferencesDefinition(payload: ObjectReferenceDto[]) {
      const responses = await Promise.all(payload.map((elem) => DefinitionService.listResourcesAndPermissions(elem.type, elem.id)))
      dispatch.definition.setDefinitions({
          relationships: responses.reduce((acc: RelationshipDto[], elem) => {
            return acc.concat(elem.relationships)
          }, []),
          permissions: responses.reduce((acc: Definitions["permissions"], elem) => {
            return {
              ...acc,
              ...elem.permissions,
            }
          }, {}),
        },
      )
    },

    async addObjectReferenceDefinition(payload: ObjectReferenceDto, state) {
      const updatedDef = await DefinitionService.listResourcesAndPermissions(payload.type, payload.id)
      dispatch.definition.setDefinitions({
        relationships: state.definition.definition.relationships.concat(updatedDef.relationships),
        permissions: {
          ...state.definition.definition.permissions,
          ...updatedDef.permissions,
        },
      })
    },

    async loadSubjectPermissions(payload: { subjectId: string, subjectType: SubjectType }) {
      dispatch.definition.setLoading(true)
      dispatch.definition.setUserPermissions(await DefinitionService.listSubjectPermissions(payload.subjectId, payload.subjectType))
      dispatch.definition.setLoading(false)
    },

    async updateCustomer(payload: CustomerDtoDetail, state) {
      const updated = await CustomerSerivce.updateCustomers(String(payload.id), payload)
      notification.info({
        message: "Customer settings saved",
      })
      const idx = state.definition.customers.findIndex((elem) => elem.id === updated.id)
      const customers = [...state.definition.customers]
      customers[idx] = updated
      dispatch.definition.setCustomers(customers)
    },

    async deactivateCustomer(payload: CustomerDtoDetail, state) {
      const updated = await CustomerSerivce.deactivateCustomer(String(payload.id))
      notification.info({
        message: "Customer deactivated",
      })
      const idx = state.definition.customers.findIndex((elem) => elem.id === updated.id)
      const customers = [...state.definition.customers]
      customers[idx] = updated
      dispatch.definition.setCustomers(customers)
    },

    async addSourceDatabase(payload: {
      dto: SourceDatabaseDtoCreateOrUpdate,
      versionCode: string,
    }, state) {
      const res = await SourceDatabaseService.createDatabase(payload.versionCode, payload.dto)
      const customerIdx = state.definition.customers.findIndex((customer) => customer.id === res.version.sequence.customer.id)
      const sequenceIdx = state.definition.customers[customerIdx].sequences.findIndex((sequence) => sequence.id === res.version.sequence.id)
      const versionIdx = state.definition.customers[customerIdx].sequences[sequenceIdx].versions.findIndex((version) => version.id === res.version.id)
      const {customers} = state.definition

      customers[customerIdx].sequences[sequenceIdx].versions[versionIdx].sourceDatabases.push(res)
      dispatch.definition.setCustomers(JSON.parse(JSON.stringify(customers))) // quick and dirty way to trigger redux state update as deep nested objets aren't normalized
    },

    async editSourceDatabase(payload: {
      dto: SourceDatabaseDtoCreateOrUpdate,
      sourceDatabaseCode: string,
    }, state) {
      const res = await SourceDatabaseService.updateDatabase(payload.sourceDatabaseCode, payload.dto)
      const customerIdx = state.definition.customers.findIndex((customer) => customer.id === res.version.sequence.customer.id)
      const sequenceIdx = state.definition.customers[customerIdx].sequences.findIndex((sequence) => sequence.id === res.version.sequence.id)
      const versionIdx = state.definition.customers[customerIdx].sequences[sequenceIdx].versions.findIndex((version) => version.id === res.version.id)
      const sourceDbIndex = state.definition.customers[customerIdx].sequences[sequenceIdx].versions[versionIdx].sourceDatabases.findIndex((db) => db.id === res.id)
      const {customers} = state.definition

      customers[customerIdx].sequences[sequenceIdx].versions[versionIdx].sourceDatabases[sourceDbIndex] = res
      dispatch.definition.setCustomers(JSON.parse(JSON.stringify(customers))) // quick and dirty way to trigger redux state update as deep nested objets aren't normalized
    },

    async deleteSourceDatabase(sourceDatabaseCode: string, state) {
      const res = await SourceDatabaseService.deleteDatabase(sourceDatabaseCode)
      const customerIdx = state.definition.customers.findIndex((customer) => customer.id === res.sequence.customer.id)
      const sequenceIdx = state.definition.customers[customerIdx].sequences.findIndex((sequence) => sequence.id === res.sequence.id)
      const versionIdx = state.definition.customers[customerIdx].sequences[sequenceIdx].versions.findIndex((version) => version.id === res.id)
      const {customers} = state.definition

      customers[customerIdx].sequences[sequenceIdx].versions[versionIdx].sourceDatabases = res.sourceDatabases
      dispatch.definition.setCustomers(JSON.parse(JSON.stringify(customers))) // quick and dirty way to trigger redux state update as deep nested objets aren't normalized    },
    },

    async addRelationship(relationshipDto: RelationshipDto) {
      await DefinitionService.addRelationship(relationshipDto)
      dispatch.definition.loadObjectReferenceDefinition(relationshipDto.resource)
      dispatch.definition.loadResourceIds(relationshipDto.resource.type)
    },

    async addBironAnalystRelationship(payload: {
      userId: string,
      customerAbbrev: string
    }) {
      await DefinitionService.addBironAnalystRelationship(payload.customerAbbrev, payload.userId)
      dispatch.definition.loadObjectReferenceDefinition({type: "customer", id: payload.customerAbbrev})
    },

    async deleteRelationship(relationshipDto: RelationshipDto) {
      await DefinitionService.deleteRelationship(relationshipDto)
      dispatch.definition.loadObjectReferenceDefinition(relationshipDto.resource)
    },

    async deleteBironAnalystRelationship(payload: {
      userId: string,
      customerAbbrev: string
    }) {
      await DefinitionService.deleteBironAnalystRelationship(payload.customerAbbrev, payload.userId)
      dispatch.definition.loadObjectReferenceDefinition({type: "customer", id: payload.customerAbbrev})
    },
  }),
})
